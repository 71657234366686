import axios from 'axios'
const state = {
  sidebarVisible: false,
  sidebarUnfoldable: false,
  sidebarShow: true,
  organizations: [],
  projects: [],
  applications: [],
  components: [],
  securityRequirements: [],
  stories: [],
  boundaries: [],
  threats: [],
  securityExpectations: [],
  connectedComponent: [],
  sessionInfo: {},
  total_pages: 1,
  itemsPerPage: 10,
  currentPage: 1,
  searchKey: '',
  orgKey: '',
  proKey: '',
  appKey: '',
  boundaryKey: '',
  comKey: '',
  storyKey: '',
  feaKey: '',
  threats_scenario_count: 0,
  total_docs: 0,
  isLoading: false,
  orgName: '',
  proName: '',
  appName: '',
  comName: '',
  storyName: '',
  srcount: 0,
  originalComponentName: '',
  originalComponentKey: '',
  storyThreatState: [],
  storySecurityExpectationState: [],
  isCreateButtonDisabled: false,
  isAuthenticated: false,
  refreshToken: '',
  accessToken: {},
  errorMessage: '',
  alertTitle: '',
}
const mutations = {
  toggleSidebar(state) {
    state.sidebarVisible = !state.sidebarVisible
  },
  toggleUnfoldable(state) {
    state.sidebarUnfoldable = !state.sidebarUnfoldable
  },
  updateSidebarVisible(state, payload) {
    state.sidebarVisible = payload.value
  },
  offSidebar(state) {
    state.sidebarVisible = false
  },
  onSidebar(state) {
    state.sidebarVisible = true
  },
  // organization CRUD using vuex
  setPages(state, total_pages) {
    state.total_pages = total_pages
  },
  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage
  },
  setItemsPerPage(state, itemsPerPage) {
    state.itemsPerPage = itemsPerPage
  },
  setSearchKey(state, searchKey) {
    state.searchKey = searchKey
  },
  setCount(state, threats_scenario_count) {
    state.threats_scenario_count = threats_scenario_count
  },
  setTotalDocs(state, total_docs) {
    state.total_docs = total_docs
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setOrgName(state, orgName) {
    state.orgName = orgName
  },
  setProName(state, proName) {
    state.proName = proName
  },
  setAppName(state, appName) {
    state.appName = appName
  },
  setComName(state, comName) {
    state.comName = comName
  },
  setStoryName(state, storyName) {
    state.storyName = storyName
  },
  setConnects(state, comName) {
    state.connectedComponent.push(comName)
  },
  setAlertTitle(state, alertTitle) {
    state.alertTitle = alertTitle
  },
  removeConnects(state, comName) {
    state.connectedComponent.pop(comName)
  },
  addOrganization(state, newOrganization) {
    state.organizations.push(newOrganization)
  },
  setOrganizations(state, organizations) {
    state.organizations = organizations
  },
  setOriginalName(state, newName) {
    state.originalComponentName = newName
  },
  setOriginalKey(state, newKey) {
    state.originalComponentKey = newKey
  },
  setCreateButton(state, disableState) {
    state.isCreateButtonDisabled = disableState
  },
  setAuthenticate(state, authState) {
    state.isAuthenticated = authState
  },
  setSessionInfo(state, sessionInfo) {
    state.sessionInfo = sessionInfo
  },
  setRefreshToken(state, rtoken) {
    state.refreshToken = rtoken
  },
  setAccessToken(state, atoken) {
    state.accessToken = atoken
  },
  updateOrganization(state, updatedOrganization) {
    const index = state.organizations.findIndex(
      (org) => org._key === updatedOrganization._key,
    )
    if (index !== -1) {
      state.organizations[index] = updatedOrganization
    }
  },
  removeOrganization(state, organizationKey) {
    for (let i = state.organizations.length - 1; i >= 0; i--) {
      if (state.organizations[i]._key === organizationKey) {
        state.organizations.splice(i, 1)
      }
    }
  },
  addApplication(state, newApplication) {
    state.applications.push(newApplication)
  },
  setApplications(state, applications) {
    state.applications = applications
  },
  setAppKey(state, appKey) {
    state.appKey = appKey
  },
  updateApplication(state, updatedApplication) {
    const index = state.applications.findIndex(
      (application) => application._key === updatedApplication._key,
    )
    if (index !== -1) {
      state.applications[index] = updatedApplication
    }
  },
  updateSecurityRequirements(state, updateSR) {
    const index = state.securityRequirements.findIndex(
      (sr) => sr._key === updateSR._key,
    )
    if (index !== -1) {
      state.securityRequirements[index] = updateSR
    }
  },
  removeApplication(state, applicationKey) {
    for (let i = state.applications.length - 1; i >= 0; i--) {
      if (state.applications[i]._key === applicationKey) {
        state.applications.splice(i, 1)
      }
    }
  },
  setComponents(state, components) {
    state.components = components
  },
  addComponent(state, newComponent) {
    state.components.push(newComponent)
  },
  setComKey(state, comKey) {
    state.comKey = comKey
  },
  setStoryKey(state, storyKey) {
    state.storyKey = storyKey
  },
  setSecurityRequirements(state, securityRequirements) {
    state.securityRequirements = securityRequirements
  },
  setSecurityExpectations(state, securityExpectations) {
    state.securityExpectations = securityExpectations
  },
  removeSR(state, srKey) {
    for (let i = state.securityRequirements.length - 1; i >= 0; i--) {
      if (state.securityRequirements[i]._key === srKey) {
        state.securityRequirements.splice(i, 1)
      }
    }
  },
  deleteBoundary(state, boundaryKey) {
    for (let i = state.boundaries.length - 1; i >= 0; i--) {
      if (state.boundaries[i]._key === boundaryKey) {
        state.boundaries.splice(i, 1)
      }
    }
  },
  deleteThreat(state, threatKey) {
    for (let i = state.threats.length - 1; i >= 0; i--) {
      if (state.threats[i]._key === threatKey) {
        state.threats.splice(i, 1)
      }
    }
  },
  setStories(state, stories) {
    state.stories = stories
  },
  addStory(state, story) {
    state.stories.push(story)
  },
  updateStory(state, updatedStory) {
    const index = state.stories.findIndex(
      (story) => story._key === updatedStory._key,
    )
    if (index !== -1) {
      state.stories[index] = updatedStory
    }
  },
  updateSecurityExpectation(state, updatedExpectation) {
    const index = state.securityExpectations.findIndex(
      (exp) => exp._key === updatedExpectation._key,
    )
    if (index !== -1) {
      state.securityExpectations[index] = updatedExpectation
    }
  },
  setBoundaries(state, boundaries) {
    state.boundaries = boundaries
  },
  setThreats(state, threats) {
    state.threats = threats
  },
  updateBoundary(state, updatedBoundary) {
    const index = state.boundaries.findIndex(
      (boundary) => boundary._key === updatedBoundary._key,
    )
    if (index !== -1) {
      state.boundaries[index] = updatedBoundary
    }
  },
  updateThreat(state, updatedThreat) {
    const index = state.threats.findIndex(
      (boundary) => boundary._key === updatedThreat._key,
    )
    if (index !== -1) {
      state.threats[index] = updatedThreat
    }
  },
  addBoundary(state, boundary) {
    state.boundaries.push(boundary)
  },
  setSRCount(state, srCount) {
    state.srcount = srCount
  },
  setErrorMessage(state, content) {
    state.errorMessage = content
  },
}
const actions = {
  async createStory({ commit }, newStory) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      const response = await axios.put(
        `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/`,
        newStory,
        { headers },
      )
      commit('addStory', response.data.data)
      commit('setTotalDocs', state.total_docs + 1)
      commit('setErrorMessage', '')
      commit('setAlertTitle', '')
    } catch (error) {
      console.error('Error adding story:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
  async fetchStory({ commit, state }) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      if (state.searchKey.length == 0) {
        const response = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
          { headers },
        )
        commit(
          'setPages',
          response.data.total_docs % 10 == 0 &&
            response.data.total_docs / 10 >= 1
            ? response.data.total_pages - 1
            : response.data.total_pages,
        )
        commit('setTotalDocs', response.data.total_docs)
        commit('setStories', response.data.data)
        commit('setIsLoading', false)
        commit('setCurrentPage', response.data.page)
        commit('setErrorMessage', '')
        commit('setAlertTitle', '')
        let tem_stories = state.stories
        for (let i = 0; i < tem_stories.length; i++) {
          const storyThreat = axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/story/${tem_stories[i]._key}?page=1&size=100`,
            { headers },
          )
          if ((await storyThreat).data.total_docs !== 0)
            state.storyThreatState[i] = true
          else state.storyThreatState[i] = false
          const storySecurityExpecation = axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/security-expcation/?story_key=${tem_stories[i]._key}&page=1&size=10`,
            { headers },
          )
          const te_key = tem_stories[i]._key
          if ((await storySecurityExpecation).data.total_docs !== 0)
            state.storySecurityExpectationState[te_key] = true
          else state.storySecurityExpectationState[te_key] = false
        }
      } else {
        const response = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          { headers },
        )
        commit('setStories', response.data.data)
        commit(
          'setPages',
          response.data.total_docs % 10 == 0 &&
            response.data.total_docs / 10 >= 1
            ? response.data.total_pages - 1
            : response.data.total_pages,
        )
        commit('setIsLoading', false)
        commit('setCurrentPage', response.data.page)
        commit('setTotalDocs', response.data.total_docs)
        commit('setErrorMessage', '')
        commit('setAlertTitle', '')
        let tem_stories = state.stories
        for (let i = 0; i < tem_stories.length; i++) {
          const storyThreat = axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/story/${tem_stories[i]._key}?page=1&size=100`,
            { headers },
          )
          if ((await storyThreat).data.total_docs !== 0)
            state.storyThreatState[i] = true
          else state.storyThreatState[i] = false
          const storySecurityExpecation = axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/security-expcation/?story_key=${tem_stories[i]._key}&page=1&size=10`,
            { headers },
          )
          const temp_key = tem_stories[i]._key
          if ((await storySecurityExpecation).data.total_docs !== 0)
            state.storySecurityExpectationState[temp_key] = true
          else state.storySecurityExpectationState[temp_key] = false
        }
      }
    } catch (error) {
      console.error('Error fetching stories:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
  async createThreatsForStory({ commit }, key) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      const response = await axios.post(
        `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/generate/${key}`,
        {},
        { headers },
      )
      if (response.data.error == false) {
        commit(
          'setErrorMessage',
          'Threats added to the user story to queue for AI generation.',
        )
        commit('setAlertTitle', 'info')
      } else {
        commit(
          'setErrorMessage',
          'There was an error while generating threats. Please try again!',
        )
        commit('setAlertTitle', 'warning')
      }
      commit('setIsLoading', false)
    } catch (error) {
      console.error('Error adding story:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
  async updateStory({ commit }, { key, updateStoryContent }) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      const response = await axios.patch(
        `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/${key}`,
        updateStoryContent,
        { headers },
      )
      commit('updateStory', response.data.data)
      commit('setIsLoading', false)
      commit('setErrorMessage', '')
      commit('setAlertTitle', '')
    } catch (error) {
      console.error('Error updating story:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
  async removeStory({ commit }, storyKey) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      await axios.delete(
        `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/${storyKey}`,
        { headers },
      )
      commit('deleteStory', storyKey)
      if (state.searchKey.length == 0) {
        const response = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
          { headers },
        )
        commit(
          'setPages',
          response.data.total_docs % 10 == 0 &&
            response.data.total_docs / 10 >= 1
            ? response.data.total_pages - 1
            : response.data.total_pages,
        )
        commit('setStories', response.data.data)
        commit('setIsLoading', false)
        commit('setCurrentPage', response.data.page)
        commit('setTotalDocs', state.total_docs - 1)
        commit('setErrorMessage', '')
        commit('setAlertTitle', '')
      } else {
        const response = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          { headers },
        )
        commit(
          'setPages',
          response.data.total_docs % 10 == 0 &&
            response.data.total_docs / 10 >= 1
            ? response.data.total_pages - 1
            : response.data.total_pages,
        )
        commit('setStories', response.data.data)
        commit('setIsLoading', false)
        commit('setTotalDocs', state.total_docs - 1)
        commit('setCurrentPage', response.data.page)
        commit('setErrorMessage', '')
        commit('setAlertTitle', '')
      }
    } catch (error) {
      console.error('Error removing story:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
  async generateSecurityExpectation({ commit }, key) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      const response = await axios.get(
        `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/security-expcation/generate/${key}`,
        { headers },
      )
      if (response.data.error == false) {
        commit(
          'setErrorMessage',
          'Added generate security expectation for AI Generation.',
        )
        commit('setAlertTitle', 'info')
      } else {
        commit(
          'setErrorMessage',
          'There was an error while generating. Please try again!',
        )
        commit('setAlertTitle', 'warning')
      }
      commit('setIsLoading', false)
    } catch (error) {
      console.error('Error adding story:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
