<template>
  <CHeader position="sticky" class="header-stick text-dark">
    <CContainer fluid>
      <!-- <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')"> -->
      <!-- <CHeaderToggler class="ps-1">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler> -->
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink class="header-li text-dark" @click="goToDashboard"
            >ThreatPlayBook Logo</CNavLink
          >
        </CNavItem>
        <CNavItem>
          <CNavLink class="header-li text-dark" @click="goToDashboard"
            >Dashboard</CNavLink
          >
        </CNavItem>
        <CNavItem>
          <CNavLink class="header-li text-dark" @click="goToDashboard"
            >Organizations</CNavLink
          >
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <CNavItem
          style="display: flex; align-items: center; justify-content: center"
        >
          <Toggle v-model="toggle" label="Light / Dark" class="toggle-button" />
          <CNavLink @click="logout" class="logout text-dark">
            <CIcon class="mx-2" icon="cil-lock-locked" size="lg" />Logout
          </CNavLink>
        </CNavItem>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
// import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { mapState, mapActions, mapMutations } from 'vuex'
import { logo } from '@/assets/brand/logo'
import { activateDarkMode, deactivateDarkMode } from '@/assets/js/dark-mode'
import { Toggle } from 'flowbite-vue'
export default {
  name: 'AppHeader',
  data() {
    return {
      toggle: false,
      isCreated: true,
    }
  },
  setup() {
    return {
      logo,
    }
  },
  computed: {
    ...mapState(['isAuthenticated']),
    ...mapState(['showConfig']),
  },
  created() {
    this.toggle = localStorage.getItem('isDarkMode') == 'true' ? true : false
    this.toggle ? activateDarkMode() : deactivateDarkMode()
  },
  watch: {
    toggle(newValue) {
      if (this.isCreated && !this.toggle) {
        this.isCreated = false
        return
      }
      localStorage.setItem('isDarkMode', newValue)
      this.isCreated = false
      if (localStorage.getItem('isDarkMode') == 'false') {
        deactivateDarkMode()
        return
      } else {
        activateDarkMode()
      }
    },
  },
  // if (this.$store.state.isDarkMode) {
  //   this.$store.state.isDarkMode = false
  //   deactivateDarkMode()
  //   return
  // } else {
  //   this.$store.state.isDarkMode = true
  //   activateDarkMode()
  // }
  components: { Toggle },
  methods: {
    ...mapActions(['signOut']),
    ...mapMutations(['navbarMinimize', 'toggleConfigurator']),
    toggleSidebar() {
      this.navbarMinimize()
    },
    logout() {
      localStorage.clear()
      this.signOut()
      this.$router.push('/auth')
    },
    goToDashboard() {
      localStorage.setItem('redirectPath', 'organization')
      this.$router.push('/organization')
    },
  },
}
</script>
<style>
.header-sticky {
  margin-bottom: 0px !important;
}
.currenturl p {
  margin: initial !important;
}
.logout {
  cursor: pointer;
}
.logout:hover {
  background-color: lightgrey;
  transition: background-color 1s ease;
  border-radius: 20px;
}
.header.text-white {
  background: black;
  color: white;
}
.nav-item a:hover {
  color: red !important;
  cursor: pointer !important;
}
.header.text-dark {
  background: white;
  color: black;
}
.header-li.text-dark,
.logout.text-dark {
  color: black !important;
}
.header-li.text-white,
.logout.text-white {
  color: white !important;
}
.toggle-button span:last-child.text-dark {
  color: black;
}
.toggle-button span:last-child.text-white {
  color: white;
}
</style>
