<template>
  <div>
    <AppSidebar />
    <AppHeader />
    <div
      class="wrapper d-flex flex-column min-vh-100 bg-light whole-body"
      :class="darkMode"
    >
      <div class="body">
        <router-view />
      </div>
      <!-- <AppFooter /> -->
    </div>
  </div>
</template>
<script>
// import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'DefaultLayout',
  components: {
    // AppFooter,
    AppHeader,
    AppSidebar,
  },
  methods: {
    ...mapMutations(['toggleConfigurator']),
  },
  mounted() {},
  computed: {
    ...mapState(['showConfig']),
    ...mapState(['isDarkMode']),
    darkMode() {
      return this.isDarkMode ? 'text-white' : 'text-dark'
    },
  },
}
</script>
<style>
@import '@/styles/_body.scss';
</style>
