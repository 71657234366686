import { createStore } from 'vuex'
import axios from 'axios'
import organizationModule from './organization'
import projectModule from './project'
import applicationModule from './application'
import entityComponentModule from './entityComponent'
import securityRequirementModule from './securityRequirement'
import featureModule from './feature'
import boundaryModule from './boundary'
import threatModule from './threat'
import securityExpectationModule from './securityExpectation'
import dataDictionaryModule from './dataDictionary'
import releaseModule from './release'
import storyThreatModule from './storyThreat'
import router from '../router'
// import { fetchSecurityRequirements } from '@/api/apiService'
export default createStore({
  modules: {
    organization: organizationModule,
    project: projectModule,
    application: applicationModule,
    entityComponent: entityComponentModule,
    securityRequirement: securityRequirementModule,
    feature: featureModule,
    boundary: boundaryModule,
    threat: threatModule,
    securityExpectation: securityExpectationModule,
    dataDictionary: dataDictionaryModule,
    release: releaseModule,
    storyThreat: storyThreatModule,
  },
  state: {
    sidebarVisible: false,
    sidebarUnfoldable: false,
    sidebarShow: true,
    organizations: [],
    projects: [],
    applications: [],
    components: [],
    securityRequirements: [],
    dataDictionaries: [],
    releases: [],
    stories: [],
    boundaries: [],
    threats: [],
    securityExpectations: [],
    connectedComponent: [],
    sessionInfo: {},
    total_pages: 1,
    itemsPerPage: 10,
    currentPage: 1,
    searchKey: '',
    orgKey: '',
    proKey: '',
    appKey: '',
    boundaryKey: '',
    comKey: '',
    storyKey: '',
    feaKey: '',
    threats_scenario_count: 0,
    total_docs: 0,
    isLoading: false,
    orgName: '',
    proName: '',
    appName: '',
    comName: '',
    storyName: '',
    srcount: 0,
    originalComponentName: '',
    originalComponentKey: '',
    storyThreatState: [],
    storySecurityExpectationState: [],
    isCreateButtonDisabled: false,
    isAuthenticated: false,
    refreshToken: '',
    accessToken: {},
    errorMessage: '',
    disableState: false, // make input disable when inputed email is valid
    showConfig: false,
    hideConfigButton: false,
    isDarkMode: false,
  },
  mutations: {
    toggleConfigurator(state, cond) {
      state.showConfig = cond
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    offSidebar(state) {
      state.sidebarVisible = false
    },
    onSidebar(state) {
      state.sidebarVisible = true
    },
    // organization CRUD using vuex
    setPages(state, total_pages) {
      state.total_pages = total_pages
    },
    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage
    },
    setItemsPerPage(state, itemsPerPage) {
      state.itemsPerPage = itemsPerPage
    },
    setSearchKey(state, searchKey) {
      state.searchKey = searchKey
    },
    setFeaKey(state, feaKey) {
      state.feaKey = feaKey
    },
    setCount(state, threats_scenario_count) {
      state.threats_scenario_count = threats_scenario_count
    },
    setTotalDocs(state, total_docs) {
      state.total_docs = total_docs
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setOrgName(state, orgName) {
      state.orgName = orgName
    },
    setProName(state, proName) {
      state.proName = proName
    },
    setAppName(state, appName) {
      state.appName = appName
    },
    setComName(state, comName) {
      state.comName = comName
    },
    setStoryName(state, storyName) {
      state.storyName = storyName
    },
    setConnects(state, comName) {
      state.connectedComponent.push(comName)
    },
    removeConnects(state, comName) {
      state.connectedComponent.pop(comName)
    },
    addOrganization(state, newOrganization) {
      state.organizations.push(newOrganization)
    },
    setOrganizations(state, organizations) {
      state.organizations = organizations
    },
    setOriginalName(state, newName) {
      state.originalComponentName = newName
    },
    setOriginalKey(state, newKey) {
      state.originalComponentKey = newKey
    },
    setCreateButton(state, disableState) {
      state.isCreateButtonDisabled = disableState
    },
    setAuthenticate(state, authState) {
      state.isAuthenticated = authState
    },
    setSessionInfo(state, sessionInfo) {
      state.sessionInfo = sessionInfo
    },
    setRefreshToken(state, rtoken) {
      state.refreshToken = rtoken
    },
    setAccessToken(state, atoken) {
      state.accessToken = atoken
    },
    setDisable(state, stateChange) {
      // make it disable when input email is valid
      state.disableState = stateChange
    },
    updateOrganization(state, updatedOrganization) {
      const index = state.organizations.findIndex(
        (org) => org._key === updatedOrganization._key,
      )
      if (index !== -1) {
        state.organizations[index] = updatedOrganization
      }
    },
    removeOrganization(state, organizationKey) {
      for (let i = state.organizations.length - 1; i >= 0; i--) {
        if (state.organizations[i]._key === organizationKey) {
          state.organizations.splice(i, 1)
        }
      }
    },
    // project CRUD
    addProject(state, newProject) {
      state.projects.push(newProject)
    },
    setProjects(state, projects) {
      state.projects = projects
    },
    setOrgKey(state, orgKey) {
      state.orgKey = orgKey
    },
    setProKey(state, proKey) {
      state.proKey = proKey
    },
    updateProject(state, updatedProject) {
      const index = state.projects.findIndex(
        (project) => project._key === updatedProject._key,
      )
      if (index !== -1) {
        state.projects[index] = updatedProject
      }
    },
    removeProject(state, projectKey) {
      for (let i = state.projects.length - 1; i >= 0; i--) {
        if (state.projects[i]._key === projectKey) {
          state.projects.splice(i, 1)
        }
      }
    },
    // application CRUD
    addApplication(state, newApplication) {
      state.applications.push(newApplication)
    },
    setApplications(state, applications) {
      state.applications = applications
    },
    setAppKey(state, appKey) {
      state.appKey = appKey
    },
    updateApplication(state, updatedApplication) {
      const index = state.applications.findIndex(
        (application) => application._key === updatedApplication._key,
      )
      if (index !== -1) {
        state.applications[index] = updatedApplication
      }
    },
    updateSecurityRequirements(state, updateSR) {
      const index = state.securityRequirements.findIndex(
        (sr) => sr._key === updateSR._key,
      )
      if (index !== -1) {
        state.securityRequirements[index] = updateSR
      }
    },
    removeApplication(state, applicationKey) {
      for (let i = state.applications.length - 1; i >= 0; i--) {
        if (state.applications[i]._key === applicationKey) {
          state.applications.splice(i, 1)
        }
      }
    },
    setComponents(state, components) {
      state.components = components
    },
    addComponent(state, newComponent) {
      state.components.push(newComponent)
    },
    setComKey(state, comKey) {
      state.comKey = comKey
    },
    setStoryKey(state, storyKey) {
      state.storyKey = storyKey
    },
    setSecurityRequirements(state, securityRequirements) {
      state.securityRequirements = securityRequirements
    },
    setSecurityExpectations(state, securityExpectations) {
      state.securityExpectations = securityExpectations
    },
    removeSR(state, srKey) {
      for (let i = state.securityRequirements.length - 1; i >= 0; i--) {
        if (state.securityRequirements[i]._key === srKey) {
          state.securityRequirements.splice(i, 1)
        }
      }
    },
    deleteBoundary(state, boundaryKey) {
      for (let i = state.boundaries.length - 1; i >= 0; i--) {
        if (state.boundaries[i]._key === boundaryKey) {
          state.boundaries.splice(i, 1)
        }
      }
    },
    deleteThreat(state, threatKey) {
      for (let i = state.threats.length - 1; i >= 0; i--) {
        if (state.threats[i]._key === threatKey) {
          state.threats.splice(i, 1)
        }
      }
    },
    setStories(state, stories) {
      state.stories = stories
    },
    addStory(state, story) {
      state.stories.push(story)
    },
    updateStory(state, updatedStory) {
      const index = state.stories.findIndex(
        (story) => story._key === updatedStory._key,
      )
      if (index !== -1) {
        state.stories[index] = updatedStory
      }
    },
    updateSecurityExpectation(state, updatedExpectation) {
      const index = state.securityExpectations.findIndex(
        (exp) => exp._key === updatedExpectation._key,
      )
      if (index !== -1) {
        state.securityExpectations[index] = updatedExpectation
      }
    },
    setBoundaries(state, boundaries) {
      state.boundaries = boundaries
    },
    setThreats(state, threats) {
      state.threats = threats
    },
    updateBoundary(state, updatedBoundary) {
      const index = state.boundaries.findIndex(
        (boundary) => boundary._key === updatedBoundary._key,
      )
      if (index !== -1) {
        state.boundaries[index] = updatedBoundary
      }
    },
    updateThreat(state, updatedThreat) {
      const index = state.threats.findIndex(
        (boundary) => boundary._key === updatedThreat._key,
      )
      if (index !== -1) {
        state.threats[index] = updatedThreat
      }
    },
    addBoundary(state, boundary) {
      state.boundaries.push(boundary)
    },
    setSRCount(state, srCount) {
      state.srcount = srCount
    },
    setErrorMessage(state, content) {
      state.errorMessage = content
    },
  },
  actions: {
    signOut({ commit }) {
      commit('setAuthenticate', false)
      commit('setSessionInfo', null)
    },
    signIn({ commit }, sessionInfor) {
      commit('setAuthenticate', true)
      commit('setSessionInfo', sessionInfor)
    },
    async getRefreshAccessToken({ commit }) {
      const refreshToken = localStorage.getItem('refreshToken')
      const headers = {
        Authorization: `Bearer ${refreshToken}`,
      }
      try {
        const response = await axios.post(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/auth/session/refresh`,
          {},
          { headers },
        )
        const newAccessToken = response.headers['st-access-token']
        const newRefreshToken = response.headers['st-refresh-token']
        console.log('New access token is ', newAccessToken)
        console.log('New refresh token is ', newRefreshToken)
        // Update the access token in the store
        commit('setAccessToken', newAccessToken)
        localStorage.setItem('accessToken', newAccessToken)
        localStorage.setItem('refreshToken', newRefreshToken)
        return newAccessToken
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert('Refresh token is expired. You need to sign out')
          // when refresh token is expired
          localStorage.clear()
          commit('setAuthenticate', false)
          commit('setIsLoading', false)
          router.push('/auth')
        }
        throw error
      }
    },
  },
})
