import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import './styles/index.css'
const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)
const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo'))
const redirectPath = localStorage.getItem('redirectPath')
if (sessionInfo) {
  store.commit('setAuthenticate', true)
  store.commit('setSessionInfo', sessionInfo)
  router.push(redirectPath)
}
app.mount('#app')
