import { createRouter, createWebHashHistory } from 'vue-router'
import { mapState } from 'vuex'
import DefaultLayout from '@/layouts/DefaultLayout'
import store from '../store'
import axios from 'axios'
const routes = [
  {
    path: '/auth',
    name: 'Login',
    component: () => import('@/views/base/Login.vue'),
    beforeEnter(to, from, next) {
      document.body.classList.add('login-page-body')
      next()
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/components/ErrorComponent.vue'),
  },
  {
    path: '/',
    name: 'Organizations',
    component: () => import('@/views/base/Organizations.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter(to, from, next) {
      document.body.classList.remove('login-page-body')
      next()
    },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: DefaultLayout,
    children: [
      {
        path: '/organization',
        name: 'Organizations',
        component: () => import('@/views/base/Organizations.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/:organizationKey',
        name: 'Projects',
        component: () => import('@/views/base/Projects.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/:organizationKey/:projectKey',
        name: 'Applications',
        component: () => import('@/views/base/Application.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey',
        name: 'Entity',
        component: () => import('@/views/base/EntityComponent.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Entity&Component',
        name: 'Component',
        component: () => import('@/views/base/EntityComponent.vue'),
      },
      {
        path: '/:projectKey/:applicationKey/:children/:componentName', // Threats for Entity and component
        name: 'Threats',
        component: () => import('@/views/base/ComponentThreat.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Security&Requirement',
        name: 'Security & Requirements',
        component: () => import('@/views/base/SecurityRequirement.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Feature',
        name: 'Features',
        component: () => import('@/views/base/Feature.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/:featureKey/Expectations', // security expectations for feature & user story
        name: 'Expectations',
        component: () => import('@/views/base/SecurityExpectation.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Boundary',
        name: 'Boundaries',
        component: () => import('@/views/base/Boundary.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Threat',
        name: 'Threat',
        component: () => import('@/views/base/Threat.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/:componentKey/Threats',
        name: 'Threats',
        component: () => import('@/views/base/Threat.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/:storyKey/Story-Threats',
        name: 'StoryThreats',
        component: () => import('@/views/base/StoryThreat.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/CounterMeasures',
        name: 'CounterMeasures',
        component: () => import('@/views/base/CounterMeasures.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/DataDictionary',
        name: 'DataDictionary',
        component: () => import('@/views/base/DataDictionary.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Release',
        name: 'Release',
        component: () => import('@/views/base/Release.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/AppUsers',
        name: 'AppUsers',
        component: () => import('@/views/base/AppUsers.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Vulnerability',
        name: 'Vulnerabilities',
        component: () => import('@/views/base/vulnerability.vue'),
      },
    ],
    meta: {
      requiresAuth: true,
    },
    beforeEnter(to, from, next) {
      document.body.classList.remove('login-page-body')
      next()
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  computed: {
    ...mapState(['isAuthenticated']),
  },
})
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.isAuthenticated) {
    next('/auth') // Redirect to login page if not authenticated
  } else {
    next()
  }
})
let isRefreshLoading = false
axios.interceptors.response.use(
  (response) => {
    const tem = window.location.href.split('/#/')
    const temp = tem.length > 1 ? tem[1] : ''
    localStorage.setItem('redirectPath', temp)
    return response
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      if (!isRefreshLoading) {
        isRefreshLoading = true
        // alert('Unauthorized error detected')
        const newAccessToken = await store.dispatch('getRefreshAccessToken')
        console.log('new access token is', newAccessToken)
        isRefreshLoading = false
        if (newAccessToken) {
          // alert('checking new access token...')
          error.config.headers['Authorization'] = `Bearer ${newAccessToken}`
          return axios.request(error.config)
        } else {
          alert('no access token')
          store.dispatch('signOut')
          router.push('/auth')
        }
      }
    }
    return Promise.reject(error)
  },
)

export default router
