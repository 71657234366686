/* eslint-disable */

export function activateDarkMode() {
  const btn_text_dark = document.querySelectorAll(
    ".btn.btn-link.text-dark, .material-icons.text-dark",
  );
  const wrapper_color = document.querySelectorAll(
    ".whole-body.text-dark",
  );
  const body_color = document.querySelectorAll(
    ".body-color.text-dark",
  );
  const th_color = document.querySelectorAll(
    "th.text-dark",
  );
  const tb_color = document.querySelectorAll(
    "td.text-dark, td a.text-dark",
  );
  const label_color = document.querySelectorAll(
    "h3.text-dark, label.text-dark",
  );
  const select_search_color = document.querySelectorAll(
    ".cformsearch.text-dark, select.text-dark",
  );
  const navigate_header_color = document.querySelectorAll(
    ".navigateHeader.text-dark",
  );
  const header_color = document.querySelectorAll(
    ".header.text-dark",
  );
  const header_element_color = document.querySelectorAll(
    ".header-li.text-dark, .logout.text-dark",
  );
  const pagination_color = document.querySelectorAll(
    ".pagination.text-dark",
  );
  const toggle_button_color = document.querySelectorAll(
    ".toggle-button span:last-child",
  );
  const accordion_color = document.querySelectorAll(
    ".accordion-item.text-dark, .accordion-header.text-dark",
  );
  for (var i = 0; i < btn_text_dark.length; i++) {
    btn_text_dark[i].classList.remove("text-dark");
    btn_text_dark[i].classList.add("text-white");
  }
  for (var i = 0; i < body_color.length; i++) {
    body_color[i].classList.remove("text-dark");
    body_color[i].classList.add("text-white");
  }
  for (var i = 0; i < th_color.length; i++) {
    th_color[i].classList.remove("text-dark");
    th_color[i].classList.add("text-white");
  }
  for (var i = 0; i < tb_color.length; i++) {
    tb_color[i].classList.remove("text-dark");
    tb_color[i].classList.add("text-white");
  }
  for (var i = 0; i < label_color.length; i++) {
    label_color[i].classList.remove("text-dark");
    label_color[i].classList.add("text-white");
  }
  for (var i = 0; i < select_search_color.length; i++) {
    select_search_color[i].classList.remove("text-dark");
    select_search_color[i].classList.add("text-white");
  }
  for (var i = 0; i < navigate_header_color.length; i++) {
    navigate_header_color[i].classList.remove("text-dark");
    navigate_header_color[i].classList.add("text-white");
  }
  for (var i = 0; i < header_color.length; i++) {
    header_color[i].classList.remove("text-dark");
    header_color[i].classList.add("text-white");
  }
  for (var i = 0; i < header_element_color.length; i++) {
    header_element_color[i].classList.remove("text-dark");
    header_element_color[i].classList.add("text-white");
  }
  for (var i = 0; i < pagination_color.length; i++) {
    pagination_color[i].classList.remove("text-dark");
    pagination_color[i].classList.add("text-white");
  }
  for (var i = 0; i < toggle_button_color.length; i++) {
    toggle_button_color[i].classList.remove("text-dark");
    toggle_button_color[i].classList.add("text-white");
  }
  for (var i = 0; i < wrapper_color.length; i++) {
    wrapper_color[i].classList.remove("text-dark");
    wrapper_color[i].classList.add("text-white");
  }
  for (var i = 0; i < accordion_color.length; i++) {
    accordion_color[i].classList.remove("text-dark");
    accordion_color[i].classList.add("text-white");
  }
}

export function deactivateDarkMode() {
  const btn_text_dark = document.querySelectorAll(
    ".btn.btn-link.text-white, .material-icons.text-dark",
  );
  const body_color = document.querySelectorAll(
    ".body-color.text-white",
  );
  const wrapper_color = document.querySelectorAll(
    ".whole-body.text-white",
  );
  const th_color = document.querySelectorAll(
    "th.text-white",
  );
  const tb_color = document.querySelectorAll(
    "td.text-white, td a.text-white",
  );
  const label_color = document.querySelectorAll(
    "h3.text-white, label.text-white",
  );
  const select_search_color = document.querySelectorAll(
    ".cformsearch.text-white, select.text-white",
  );
  const navigate_header_color = document.querySelectorAll(
    ".navigateHeader.text-white",
  );
  const header_color = document.querySelectorAll(
    ".header.text-white",
  );
  const header_element_color = document.querySelectorAll(
    ".header-li.text-white, .logout.text-white",
  );
  const pagination_color = document.querySelectorAll(
    ".pagination.text-white",
  );
  const toggle_button_color = document.querySelectorAll(
    ".toggle-button span:last-child",
  );
  const accordion_color = document.querySelectorAll(
    ".accordion-item.text-white, .accordion-header.text-white",
  );
  for (var i = 0; i < btn_text_dark.length; i++) {
    btn_text_dark[i].classList.remove("text-white");
    btn_text_dark[i].classList.add("text-dark");
  }
  for (var i = 0; i < body_color.length; i++) {
    body_color[i].classList.remove("text-white");
    body_color[i].classList.add("text-dark");
  }
  for (var i = 0; i < th_color.length; i++) {
    th_color[i].classList.remove("text-white");
    th_color[i].classList.add("text-dark");
  }
  for (var i = 0; i < tb_color.length; i++) {
    tb_color[i].classList.remove("text-white");
    tb_color[i].classList.add("text-dark");
  }
  for (var i = 0; i < label_color.length; i++) {
    label_color[i].classList.remove("text-white");
    label_color[i].classList.add("text-dark");
  }
  for (var i = 0; i < select_search_color.length; i++) {
    select_search_color[i].classList.remove("text-white");
    select_search_color[i].classList.add("text-dark");
  }
  for (var i = 0; i < navigate_header_color.length; i++) {
    navigate_header_color[i].classList.remove("text-white");
    navigate_header_color[i].classList.add("text-dark");
  }
  for (var i = 0; i < header_color.length; i++) {
    header_color[i].classList.remove("text-white");
    header_color[i].classList.add("text-dark");
  }
  for (var i = 0; i < header_element_color.length; i++) {
    header_element_color[i].classList.remove("text-white");
    header_element_color[i].classList.add("text-dark");
  }
  for (var i = 0; i < pagination_color.length; i++) {
    pagination_color[i].classList.remove("text-white");
    pagination_color[i].classList.add("text-dark");
  }
  for (var i = 0; i < toggle_button_color.length; i++) {
    toggle_button_color[i].classList.remove("text-white");
    toggle_button_color[i].classList.add("text-dark");
  }
  for (var i = 0; i < wrapper_color.length; i++) {
    wrapper_color[i].classList.remove("text-white");
    wrapper_color[i].classList.add("text-dark");
  }
  for (var i = 0; i < accordion_color.length; i++) {
    accordion_color[i].classList.remove("text-white");
    accordion_color[i].classList.add("text-dark");
  }
}
