import axios from 'axios'
const state = {
  sidebarVisible: false,
  sidebarUnfoldable: false,
  sidebarShow: true,
  organizations: [],
  projects: [],
  applications: [],
  components: [],
  dataDictionaries: [],
  stories: [],
  boundaries: [],
  threats: [],
  securityExpectations: [],
  connectedComponent: [],
  sessionInfo: {},
  total_pages: 1,
  itemsPerPage: 10,
  currentPage: 1,
  searchKey: '',
  orgKey: '',
  proKey: '',
  appKey: '',
  boundaryKey: '',
  comKey: '',
  storyKey: '',
  feaKey: '',
  threats_scenario_count: 0,
  total_docs: 0,
  isLoading: false,
  orgName: '',
  proName: '',
  appName: '',
  comName: '',
  storyName: '',
  srcount: 0,
  originalComponentName: '',
  originalComponentKey: '',
  storyThreatState: [],
  storySecurityExpectationState: [],
  isCreateButtonDisabled: false,
  isAuthenticated: false,
  refreshToken: '',
  accessToken: {},
  errorMessage: '',
  alertTitle: '',
}
const mutations = {
  toggleSidebar(state) {
    state.sidebarVisible = !state.sidebarVisible
  },
  toggleUnfoldable(state) {
    state.sidebarUnfoldable = !state.sidebarUnfoldable
  },
  updateSidebarVisible(state, payload) {
    state.sidebarVisible = payload.value
  },
  offSidebar(state) {
    state.sidebarVisible = false
  },
  onSidebar(state) {
    state.sidebarVisible = true
  },
  setPages(state, total_pages) {
    state.total_pages = total_pages
  },
  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage
  },
  setItemsPerPage(state, itemsPerPage) {
    state.itemsPerPage = itemsPerPage
  },
  setSearchKey(state, searchKey) {
    state.searchKey = searchKey
  },
  setCount(state, threats_scenario_count) {
    state.threats_scenario_count = threats_scenario_count
  },
  setTotalDocs(state, total_docs) {
    state.total_docs = total_docs
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setOrgName(state, orgName) {
    state.orgName = orgName
  },
  setProName(state, proName) {
    state.proName = proName
  },
  setAppName(state, appName) {
    state.appName = appName
  },
  setComName(state, comName) {
    state.comName = comName
  },
  setAlertTitle(state, alertTitle) {
    state.alertTitle = alertTitle
  },
  setOriginalName(state, newName) {
    state.originalComponentName = newName
  },
  setOriginalKey(state, newKey) {
    state.originalComponentKey = newKey
  },
  setAuthenticate(state, authState) {
    state.isAuthenticated = authState
  },
  setSessionInfo(state, sessionInfo) {
    state.sessionInfo = sessionInfo
  },
  setRefreshToken(state, rtoken) {
    state.refreshToken = rtoken
  },
  setAccessToken(state, atoken) {
    state.accessToken = atoken
  },
  setErrorMessage(state, content) {
    state.errorMessage = content
  },
  setAppKey(state, appKey) {
    state.appKey = appKey
  },
  updateDataDictionary(state, updateDd) {
    const index = state.dataDictionaries.findIndex(
      (dd) => dd._key === updateDd._key,
    )
    if (index !== -1) {
      state.dataDictionaries[index] = updateDd
    }
  },
  setDataDictionaries(state, dataDictionaries) {
    state.dataDictionaries = dataDictionaries
  },
  removeDD(state, ddKey) {
    for (let i = state.dataDictionaries.length - 1; i >= 0; i--) {
      if (state.dataDictionaries[i]._key === ddKey) {
        state.dataDictionaries.splice(i, 1)
      }
    }
  },
  setSRCount(state, srCount) {
    state.srcount = srCount
  },
  addDataDictionary(state, newDataDic) {
    state.dataDictionaries.push(newDataDic)
  },
}
const actions = {
  async fetchDataDictionary({ commit, state }) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      const response = await axios.get(
        `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/data-dictionary/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
        { headers },
      )
      commit('setTotalDocs', response.data.total_docs)
      commit(
        'setPages',
        response.data.total_docs % 10 == 0 && response.data.total_docs / 10 >= 1
          ? response.data.total_pages - 1
          : response.data.total_pages,
      )
      commit('setIsLoading', false)
      commit('setDataDictionaries', response.data.data)
      commit('setCurrentPage', response.data.page)
      commit('setErrorMessage', '')
      commit('setAlertTitle', '')
      console.log(response.data.data)
    } catch (error) {
      console.error('Error fetching data dictionaries:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
  async editDataDictionary({ commit }, { key, updatedData }) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      const response = await axios.patch(
        `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/data-dictionary/${key}`,
        updatedData,
        { headers },
      )
      if (response.data.success == true) {
        commit('setIsLoading', false)
        commit('setErrorMessage', 'Successful updated!')
        commit('setAlertTitle', 'info')
      }
      commit('updateDataDictionary', response.data.data)
    } catch (error) {
      console.error('Error updating security requirement:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
  async createDataDictionary({ commit }, newDataDictionary) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      const response = await axios.put(
        'https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/data-dictionary/',
        newDataDictionary,
        { headers },
      )
      commit('addDataDictionary', response.data.data)
      const response1 = await axios.get(
        `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/data-dictionary/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
        { headers },
      )
      commit('setDataDictionaries', response1.data.data)
      commit(
        'setPages',
        response1.data.total_docs % 10 == 0 &&
          response1.data.total_docs / 10 >= 1
          ? response1.data.total_pages - 1
          : response1.data.total_pages,
      )
      commit('setCurrentPage', response1.data.page)
      commit('setIsLoading', false)
      commit('setTotalDocs', response1.data.total_docs)
      commit('setItemsPerPage', response1.data.size)
      commit('setAlertTitle', '')
      commit('setErrorMessage', '')
    } catch (error) {
      console.error('Error adding project:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
  async removeDataDictionary({ commit }, ddKey) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      await axios.delete(
        `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/data-dictionary/${ddKey}`,
        { headers },
      )
      commit('setIsLoading', false)
      commit('removeDD', ddKey)
      const response = await axios.get(
        `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/data-dictionary/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
        { headers },
      )
      commit(
        'setPages',
        response.data.total_docs % 10 == 0 && response.data.total_docs / 10 >= 1
          ? response.data.total_pages - 1
          : response.data.total_pages,
      )
      commit('setIsLoading', false)
      commit('setCurrentPage', response.data.page)
      commit('setErrorMessage', '')
      commit('setAlertTitle', '')
    } catch (error) {
      console.error('Error removing data dictionary:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
