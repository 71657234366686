import axios from 'axios'
const state = {
  sidebarVisible: false,
  sidebarUnfoldable: false,
  sidebarShow: true,
  organizations: [],
  projects: [],
  applications: [],
  components: [],
  securityRequirements: [],
  stories: [],
  boundaries: [],
  threats: [],
  securityExpectations: [],
  connectedComponent: [],
  sessionInfo: {},
  total_pages: 1,
  itemsPerPage: 10,
  currentPage: 1,
  searchKey: '',
  orgKey: '',
  proKey: '',
  appKey: '',
  boundaryKey: '',
  comKey: '',
  storyKey: '',
  feaKey: '',
  threats_scenario_count: 0,
  total_docs: 0,
  isLoading: false,
  orgName: '',
  proName: '',
  appName: '',
  comName: '',
  storyName: '',
  srcount: 0,
  originalComponentName: '',
  originalComponentKey: '',
  storyThreatState: [],
  storySecurityExpectationState: [],
  isCreateButtonDisabled: false,
  isAuthenticated: false,
  refreshToken: '',
  accessToken: {},
  errorMessage: '',
  alertTitle: '',
}
const mutations = {
  toggleSidebar(state) {
    state.sidebarVisible = !state.sidebarVisible
  },
  toggleUnfoldable(state) {
    state.sidebarUnfoldable = !state.sidebarUnfoldable
  },
  updateSidebarVisible(state, payload) {
    state.sidebarVisible = payload.value
  },
  offSidebar(state) {
    state.sidebarVisible = false
  },
  onSidebar(state) {
    state.sidebarVisible = true
  },
  setPages(state, total_pages) {
    state.total_pages = total_pages
  },
  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage
  },
  setItemsPerPage(state, itemsPerPage) {
    state.itemsPerPage = itemsPerPage
  },
  setSearchKey(state, searchKey) {
    state.searchKey = searchKey
  },
  setCount(state, threats_scenario_count) {
    state.threats_scenario_count = threats_scenario_count
  },
  setTotalDocs(state, total_docs) {
    state.total_docs = total_docs
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setOrgName(state, orgName) {
    state.orgName = orgName
  },
  setProName(state, proName) {
    state.proName = proName
  },
  setAppName(state, appName) {
    state.appName = appName
  },
  setComName(state, comName) {
    state.comName = comName
  },
  setStoryName(state, storyName) {
    state.storyName = storyName
  },
  setConnects(state, comName) {
    state.connectedComponent.push(comName)
  },
  setAlertTitle(state, alertTitle) {
    state.alertTitle = alertTitle
  },
  removeConnects(state, comName) {
    state.connectedComponent.pop(comName)
  },
  addOrganization(state, newOrganization) {
    state.organizations.push(newOrganization)
  },
  setOrganizations(state, organizations) {
    state.organizations = organizations
  },
  setOriginalName(state, newName) {
    state.originalComponentName = newName
  },
  setOriginalKey(state, newKey) {
    state.originalComponentKey = newKey
  },
  setCreateButton(state, disableState) {
    state.isCreateButtonDisabled = disableState
  },
  setAuthenticate(state, authState) {
    state.isAuthenticated = authState
  },
  setSessionInfo(state, sessionInfo) {
    state.sessionInfo = sessionInfo
  },
  setRefreshToken(state, rtoken) {
    state.refreshToken = rtoken
  },
  setAccessToken(state, atoken) {
    state.accessToken = atoken
  },
  updateOrganization(state, updatedOrganization) {
    const index = state.organizations.findIndex(
      (org) => org._key === updatedOrganization._key,
    )
    if (index !== -1) {
      state.organizations[index] = updatedOrganization
    }
  },
  removeOrganization(state, organizationKey) {
    for (let i = state.organizations.length - 1; i >= 0; i--) {
      if (state.organizations[i]._key === organizationKey) {
        state.organizations.splice(i, 1)
      }
    }
  },
  addProject(state, newProject) {
    state.projects.push(newProject)
  },
  setProjects(state, projects) {
    state.projects = projects
  },
  setOrgKey(state, orgKey) {
    state.orgKey = orgKey
  },
  setProKey(state, proKey) {
    state.proKey = proKey
  },
  setErrorMessage(state, content) {
    state.errorMessage = content
  },
  updateProject(state, updatedProject) {
    const index = state.projects.findIndex(
      (project) => project._key === updatedProject._key,
    )
    if (index !== -1) {
      state.projects[index] = updatedProject
    }
  },
  removeProject(state, projectKey) {
    for (let i = state.projects.length - 1; i >= 0; i--) {
      if (state.projects[i]._key === projectKey) {
        state.projects.splice(i, 1)
      }
    }
  },
  setSRCount(state, srCount) {
    state.srcount = srCount
  },
}
const actions = {
  async fetchProject({ commit, state }) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      if (state.searchKey.length == 0) {
        const response = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/?org=${state.orgKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
          { headers },
        )
        commit('setProjects', response.data.data)
        commit(
          'setPages',
          response.data.total_docs % 10 == 0 &&
            response.data.total_docs / 10 >= 1
            ? response.data.total_pages - 1
            : response.data.total_pages,
        )
        commit('setCurrentPage', response.data.page)
        commit('setItemsPerPage', response.data.size)
        commit('setIsLoading', false)
        commit('setTotalDocs', response.data.total_docs)
        commit('setErrorMessage', '')
        commit('setAlertTitle', '')
      } else {
        const response = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/?org=${state.orgKey}&page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          { headers },
        )
        commit('setProjects', response.data.data)
        commit(
          'setPages',
          response.data.total_docs % 10 == 0 &&
            response.data.total_docs / 10 >= 1
            ? response.data.total_pages - 1
            : response.data.total_pages,
        )
        commit('setCurrentPage', response.data.page)
        commit('setIsLoading', false)
        commit('setItemsPerPage', response.data.size)
        commit('setTotalDocs', response.data.total_docs)
        commit('setAlertTitle', '')
        commit('setErrorMessage', '')
      }
    } catch (error) {
      console.error('Error fetching projects:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
  async addProject({ commit }, newProject) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      const response = await axios.put(
        'https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/',
        newProject,
        { headers },
      )
      commit('addProject', response.data.data)
      if (state.searchKey.length == 0) {
        const response = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/?org=${state.orgKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
          { headers },
        )
        commit('setProjects', response.data.data)
        commit(
          'setPages',
          response.data.total_docs % 10 == 0 &&
            response.data.total_docs / 10 >= 1
            ? response.data.total_pages - 1
            : response.data.total_pages,
        )
        commit('setCurrentPage', response.data.page)
        commit('setIsLoading', false)
        commit('setTotalDocs', response.data.total_docs)
        commit('setItemsPerPage', response.data.size)
        commit('setAlertTitle', '')
        commit('setErrorMessage', '')
      } else {
        const response = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/?org=${state.orgKey}&page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          { headers },
        )
        commit('setProjects', response.data.data)
        commit(
          'setPages',
          response.data.total_docs % 10 == 0 &&
            response.data.total_docs / 10 >= 1
            ? response.data.total_pages - 1
            : response.data.total_pages,
        )
        commit('setCurrentPage', response.data.page)
        commit('setTotalDocs', response.data.total_docs)
        commit('setIsLoading', false)
        commit('setItemsPerPage', response.data.size)
        commit('setAlertTitle', '')
        commit('setErrorMessage', '')
      }
    } catch (error) {
      console.error('Error adding project:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
  async editProject({ commit }, { key, editedData }) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      const response = await axios.patch(
        `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/${key}`,
        editedData,
        { headers },
      )
      commit('setIsLoading', false)
      commit('updateProject', response.data.data)
      commit('setAlertTitle', '')
      commit('setErrorMessage', '')
    } catch (error) {
      console.error('Error updating project:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
  async removeProject({ commit }, projectKey) {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      await axios.delete(
        `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/${projectKey}`,
        { headers },
      )
      commit('removeProject', projectKey)
      if (state.searchKey.length == 0) {
        const response = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/?org=${state.orgKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
          { headers },
        )
        commit('setProjects', response.data.data)
        commit(
          'setPages',
          response.data.total_docs % 10 == 0 &&
            response.data.total_docs / 10 >= 1
            ? response.data.total_pages - 1
            : response.data.total_pages,
        )
        commit('setCurrentPage', response.data.page)
        commit('setIsLoading', false)
        commit('setTotalDocs', response.data.total_docs)
        commit('setItemsPerPage', response.data.size)
        commit('setAlertTitle', '')
        commit('setErrorMessage', '')
      } else {
        const response = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/?org=${state.orgKey}&page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          { headers },
        )
        commit('setProjects', response.data.data)
        commit(
          'setPages',
          response.data.total_docs % 10 == 0 &&
            response.data.total_docs / 10 >= 1
            ? response.data.total_pages - 1
            : response.data.total_pages,
        )
        commit('setIsLoading', false)
        commit('setCurrentPage', response.data.page)
        commit('setTotalDocs', response.data.total_docs)
        commit('setItemsPerPage', response.data.size)
        commit('setAlertTitle', '')
        commit('setErrorMessage', '')
      }
    } catch (error) {
      console.error('Error removing project:', error)
      commit('setIsLoading', false)
      commit('setErrorMessage', error.message)
      commit('setAlertTitle', 'warning')
    }
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
